<template>
  <div v-if="authenticated">
    <b-card :title="'Hola! ' + user.name">
      <b-card
        text-variant="center"
        text-color="white"
        class="card card-congratulations"
      >
        <b-avatar
          variant="primary"
          size="70"
          class="shadow mb-2"
        >
          <feather-icon
            size="28"
            icon="AwardIcon"
          />
        </b-avatar>
        <b-card-text>Bienvenido al sitio para la administración de la página web de COTOBOL Chuquisaca, desde este punto podrá modificarlas diferentes secciones del sitio y mantener actualizada la información </b-card-text>
        <b-card-text class="m-auto w-75">
          
        </b-card-text>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import {  BCard, BImg, BAvatar, BCardText } from 'bootstrap-vue'
import { mapGetters,mapActions } from "vuex";
export default {
  components: {
    BCard,
    BCardText,
    BAvatar,
    BImg,
  },
  computed: {
    ...mapGetters({
        authenticated:'auth/authenticated',
        user:'auth/user',
    })
  },
}
</script>

<style>

</style>
